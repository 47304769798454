<template>
  <action-form></action-form>
</template>

<script>
import ActionForm from "@/components/action/ActionForm";
export default {
  name: "ActionCreate",
  components: {
    ActionForm,
  },
};
</script>
